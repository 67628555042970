import PropTypes from 'prop-types'
import React from 'react'
import Button from './Button'

const Pagination = ({ loading, limit, setSkip, skip, total }) => {
  const page = Math.ceil(skip / limit) + 1 || 1
  const pages = Math.ceil(total / limit) || 1

  return (
    <div className='pagination flex justify-center items-center -mx-2'>
      <div className='mx-2'>
        <Button
          color='neutral'
          disabled={loading || skip <= 0}
          onClick={() => setSkip(0)}
        >
          <span className='text-xl' role='img' aria-label='confused'>
            ⏮
          </span>
        </Button>
      </div>

      <div className='mx-2'>
        <Button
          color='neutral'
          disabled={loading || skip <= 0}
          onClick={() => setSkip(skip - limit)}
        >
          <span className='text-xl' role='img' aria-label='confused'>
            ⏪
          </span>
        </Button>
      </div>

      <div className='mx-2'>
        {page} / {pages}
      </div>

      <div className='mx-2'>
        <Button
          color='neutral'
          disabled={loading || page >= pages || pages <= 1}
          onClick={() => setSkip(skip + limit)}
        >
          <span className='text-xl' role='img' aria-label='confused'>
            ⏩
          </span>
        </Button>
      </div>
      <div className='mx-2'>
        <Button
          color='neutral'
          disabled={loading || page >= pages || pages <= 1}
          onClick={() => setSkip(Math.ceil(total / limit) * limit - limit)}
        >
          <span className='text-xl' role='img' aria-label='confused'>
            ⏭
          </span>
        </Button>
      </div>
    </div>
  )
}

Pagination.propTypes = {
  loading: PropTypes.bool.isRequired,
  limit: PropTypes.number.isRequired,
  setSkip: PropTypes.func.isRequired,
  skip: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired
}

export default Pagination
