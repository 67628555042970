import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { view } from 'react-easy-state'
import { Helmet } from 'react-helmet'
import { Redirect } from 'react-router-dom'
import auth from '../api/authentication'
import Button from '../components/Button'
import Field from '../components/Field'
import Header from '../components/Header'
import useNotifications from '../hooks/notifications'
import state from '../state'
import { getFormData } from '../utils/data'

const Login = ({ location }) => {
  const { from } = location.state || { from: { pathname: '/' } }

  const { addNotification } = useNotifications()

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      const data = getFormData(e.target)
      await auth.login(data)
    } catch (error) {
      addNotification(error)
    }
  }

  useEffect(() => {
    auth.logout()
  }, [])

  return state.session ? (
    <Redirect to={{ pathname: from.pathname }} />
  ) : (
    <div className='flex h-screen justify-center p-4'>
      <Helmet>
        <title>{window.document.head.dataset.titlePrefix} | Anmelden</title>
      </Helmet>

      <form
        className='bg-white rounded p-8 self-center shadow-xl'
        onSubmit={handleSubmit}
      >
        <Header />

        <h2 className='text-lg text-gray-700 mb-3'>Bitte anmelden:</h2>

        <div className='my-3'>
          <Field label='E-Mail-Adresse' name='email' required type='email' />
        </div>

        <div className='my-3'>
          <Field label='Passwort' name='password' required type='password' />
        </div>

        <div className='mt-6 flex justify-end'>
          <Button color='primary' disabled={state.semaphore > 0} type='submit'>
            Anmelden
          </Button>
        </div>
      </form>
    </div>
  )
}

Login.propTypes = {
  location: PropTypes.object.isRequired
}

export default view(Login)
