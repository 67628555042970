import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'

const Stub = ({ location }) => (
  <div>
    <Helmet>
      <title>
        {window.document.head.dataset.titlePrefix} | {location.pathname}
      </title>
    </Helmet>

    <h1 className='text-3xl'>{location.pathname}</h1>
  </div>
)

Stub.propTypes = {
  location: PropTypes.object.isRequired
}

export default Stub
