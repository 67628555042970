import PropTypes from 'prop-types'
import React from 'react'
import Input from '../Input'
import items from '../../settings/items'

const reducer = (acc, item) => {
  if (item.fieldset) {
    return item.fieldset.reduce(reducer, acc)
  }
  return [...acc, item.name]
}

const TagSelect = ({
  extraFields = [],
  handleChange = console.log,
  type,
  ...props
}) => {
  const item = items(type)

  return (
    <Input
      defaultValue=''
      onChange={handleChange}
      options={[
        { disabled: true, label: item.subject.singular, value: '' },
        ...item.fields
          .reduce(reducer, extraFields)
          .sort((a, b) => a.localeCompare(b))
          .map(label => ({ label, value: `${type}:${label}` }))
      ]}
      type='select'
      {...props}
    />
  )
}

TagSelect.propTypes = {
  extraFields: PropTypes.array,
  handleChange: PropTypes.func,
  type: PropTypes.string.isRequired
}

export default TagSelect
