export default {
  danger: {
    bg: 'bg-red-600',
    border: 'border-red-300',
    fg: 'text-white',
    focus: 'focus:border-red-700',
    hover: 'hover:bg-red-700'
  },
  default: {
    bg: 'bg-white',
    border: 'border-gray-400',
    fg: 'text-blue-600',
    focus: 'focus:border-blue-700',
    hover: 'hover:bg-gray-100'
  },
  neutral: {
    bg: '',
    border: '',
    fg: 'text-blue-700',
    focus: '',
    hover: ''
  },
  primary: {
    bg: 'bg-blue-600',
    border: 'border-blue-300',
    fg: 'text-white',
    focus: 'focus:border-blue-700',
    hover: 'hover:bg-blue-700'
  },
  success: {
    bg: 'bg-green-600',
    border: 'border-green-300',
    fg: 'text-white',
    focus: 'focus:border-green-700',
    hover: 'hover:bg-green-700'
  },
  warning: {
    bg: 'bg-yellow-600',
    border: 'border-yellow-300',
    fg: 'text-white',
    focus: 'focus:border-yellow-700',
    hover: 'hover:bg-yellow-700'
  }
}
