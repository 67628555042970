import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import Button from '../components/Button'

export default class ErrorBoundary extends Component {
  state = { error: null }

  static getDerivedStateFromError (error) {
    return { error }
  }

  componentDidCatch (error, info) {
    console.error(error, info)
  }

  render () {
    const { error } = this.state
    return error ? (
      <div className='flex h-screen justify-center p-4'>
        <Helmet>
          <title>{window.document.head.dataset.titlePrefix} | Fehler!</title>
        </Helmet>
        <div className='bg-white rounded p-8 self-center text-center shadow-xl'>
          <h1 className='text-6xl'>
            <span role='img' aria-label='confused'>
              😧
            </span>
          </h1>

          <p>Es ist ein Fehler aufgetreten: </p>

          <p>
            <em style={{ wordBreak: 'break-all' }}>
              "{this.state.error.message}"
            </em>
          </p>

          <p className='mt-6'>
            <Button onClick={() => window.location.reload()} type='button'>
              App neu starten
            </Button>
          </p>
        </div>
      </div>
    ) : (
      this.props.children
    )
  }
}
