import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import Button from '../components/Button'

const items = [
  {
    label: 'Buchungen',
    to: '/admin/reservations'
  },
  {
    label: 'Termine',
    to: '/admin/events'
  },
  {
    label: 'Reitschule',
    to: '/admin/ridingschool'
  },
  {
    label: 'Einstellungen',
    to: '/admin/einstellungen'
  },
  {
    label: 'Abmelden',
    onClick: event =>
      !window.confirm('Wirklich abmelden?') && event.preventDefault(),
    to: '/login'
  }
]

const Menu = ({ path }) => (
  <div className='py-4' id='menu'>
    <ul className='flex flex-wrap -m-1'>
      {items.map(({ label, to, ...props }) => (
        <li className='m-1' key={to} style={{ height: 35 }}>
          <Button
            color={
              path === to || (path.startsWith(to) && !to.endsWith('admin'))
                ? 'primary'
                : 'default'
            }
            component={Link}
            to={to}
            {...props}
          >
            {label}
          </Button>
        </li>
      ))}
    </ul>
  </div>
)

Menu.propTypes = {
  path: PropTypes.string.isRequired
}

export default Menu
