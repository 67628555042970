import nanoid from 'nanoid'
import globalState from '../state'

const addNotification = data => {
  const id = nanoid()
  const type = data.message ? 'danger' : data.type || 'default'
  const notification = {
    id,
    payload: `${
      data.message ? `${data.name || 'Fehler'}: ${data.message}` : data.payload
    }`,
    timerId: !['danger', 'warning'].includes(type)
      ? setTimeout(() => removeNotification(id), 5000)
      : undefined,
    type
  }

  if (data.message) console.error(data)

  globalState.notifications = [...globalState.notifications, notification]

  return notification
}

const cancelAutoremove = _id => {
  const index = globalState.notifications.findIndex(({ id }) => id === _id)
  if (index < 0) return
  const { timerId } = globalState.notifications[index]
  if (timerId >= 0) {
    globalState.notifications[index].timerId = undefined
    clearTimeout(timerId)
  }
}

const removeNotification = _id => {
  globalState.notifications = globalState.notifications.filter(
    ({ id }) => id !== _id
  )
}

export default () => ({ addNotification, cancelAutoremove, removeNotification })
