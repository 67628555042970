import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => window.scrollTo(0, 0), [pathname])
  return children
}

ScrollToTop.propTypes = {
  children: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
}

export default withRouter(ScrollToTop)
