import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

const Header = ({ noborder }) => (
  <header
    className={`flex items-center -mx-2 mb-4 py-4${
      noborder ? '' : ' border shadow px-2 rounded'
    }`}
  >
    <div className='mx-2'>
      <Link className='no-underline' to='/'>
        <img
          alt='Ponyhof Maikendiek Logo'
          src='https://ponyhof-maikendiek.de/wp-content/uploads/2020/06/Download-2.png'
          style={{ height: 100, minWidth: 100 }}
        />
      </Link>
    </div>
    <h1 className='text-2xl mx-2'>
      <Link className='no-underline' to='/'>
        Ponyhof Maikendiek Buchungssystem
      </Link>
    </h1>
  </header>
)

Header.propTypes = {
  noborder: PropTypes.bool
}

export default Header
