const getTypeError = el =>
  new TypeError(
    `Input "${el.name}" with type "${
      el.type
    }" was submitted with invalid value: "${el.value}" (${typeof el.value})`
  )

const getValue = el => {
  switch (el.type) {
    case 'checkbox':
      return el.checked
    case 'datetime-local':
      return new Date(el.value).toISOString()
    case 'number':
      if (isNaN(el.value)) throw getTypeError(el)
      return Number(el.value)
    default:
      return el.value
  }
}

export const getFormData = form =>
  Array.from(form)
    .filter(el => el.name)
    .filter(el => (el.type === 'radio' ? el.checked : true))
    .reduce(
      (acc, el) => ({
        ...acc,
        [el.name]: getValue(el)
      }),
      {}
    )

export const hashString = str => {
  let hash = 0

  if (typeof str !== 'string' || str.length === 0) {
    return hash
  }

  for (var i = 0; i < str.length; i++) {
    let char = str.charCodeAt(i)
    hash = (hash << 5) - hash + char
    hash = hash & hash
  }
  return hash
}
