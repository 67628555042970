import PropTypes from 'prop-types'
import React from 'react'
import { view } from 'react-easy-state'
import { Redirect } from 'react-router-dom'
import state from '../state'

const ProtectedRoute = ({ component: Component, ...rest }) =>
  state.session ? (
    <Component {...rest} />
  ) : (
    <Redirect
      to={{
        pathname: '/login',
        state: { from: rest.location }
      }}
    />
  )

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired
}

export default view(ProtectedRoute)
