import client from '../api/client'
import state from '../state'

export const authenticate = async () => {
  try {
    state.semaphore++
    const { user } = await client.reAuthenticate()
    return (state.session = user)
  } catch (error) {
    throw error
  } finally {
    state.semaphore--
  }
}

export const login = async ({ email, password }) => {
  try {
    state.semaphore++
    const { user } = await client.authenticate({
      strategy: 'local',
      email,
      password
    })
    return (state.session = user)
  } catch (error) {
    throw error
  } finally {
    state.semaphore--
  }
}

export const logout = async () => {
  try {
    state.semaphore++
    await client.logout()
    return (state.session = null)
  } catch (error) {
    throw error
  } finally {
    state.semaphore--
  }
}

export default { authenticate, login, logout }
