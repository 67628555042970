import { exec, init } from 'pell'
import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'
import TagSelect from './TagSelect'
import './index.css'

const Pell = ({ handleChange = console.log, initialContent = '' }) => {
  const element = useRef(null)
  const editor = useRef(null)

  useEffect(() => {
    if (element.current && !editor.current) {
      editor.current = init({
        actions: [
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'heading1',
          'heading2',
          'paragraph',
          'quote',
          'olist',
          'ulist',
          'link'
        ],
        classes: {
          button:
            'pell-button whitespace-no-wrap bg-white hover:bg-gray-100 text-blue-600 text-sm border py-2 px-3 rounded shadow hover:shadow-none focus:outline-none focus:border-blue-700',
          content:
            'pell-content shadow border rounded w-full py-2 px-3 hover:shadow-none focus:shadow-none focus:outline-none focus:border-blue-700',
          selected: 'pell-button-selected'
        },
        element: element.current,
        onChange: handleChange
      })
    }
    editor.current.content.innerHTML = initialContent
  }, [handleChange, initialContent])

  return (
    <>
      <div className='flex flex-wrap -m-1 pb-2'>
        <div className='m-1'>
          <TagSelect
            extraFields={['_id']}
            handleChange={({ target: { value } }) =>
              exec('insertText', `{{${value}}}`)
            }
            type='events'
          />
        </div>
        <div className='m-1'>
          <TagSelect
            extraFields={['_eventLabel']}
            handleChange={({ target: { value } }) =>
              exec('insertText', `{{${value}}}`)
            }
            type='reservations'
          />
        </div>
      </div>
      <div ref={element} />
    </>
  )
}

Pell.propTypes = {
  handleChange: PropTypes.func,
  initialContent: PropTypes.string
}

export default Pell
