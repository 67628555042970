import PCancelable from 'cancelable-promise'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import api from '../api/services'
import Button from '../components/Button'
import Field from '../components/Field'
import useNotifications from '../hooks/notifications'
import getSettings from '../settings/items'
import state from '../state'
import { getFormData } from '../utils/data'

const ItemEditor = ({
  history,
  location: { pathname },
  match: {
    params: { id }
  }
}) => {
  const [dbOptions, setDbOptions] = useState({
    events: [{ label: 'Lade…', value: '' }]
  })
  const { addNotification } = useNotifications()
  const [item, setItem] = useState({})

  const isNew = id === 'new'
  const itemType = pathname.split('/')[2]
  const settings = getSettings(itemType, item)
  const title = isNew
    ? `${settings.subject.singular} anlegen`
    : `${settings.subject.singular} bearbeiten`

  const fields = settings.fields.filter(({ hideEditor }) => !hideEditor)

  const getField = ({ name, options, parse, tableHeader, wrap, ...props }) => (
    <div className='w-full md:w-1/2 px-3 my-3' key={name}>
      <Field
        name={name}
        onChange={
          itemType === 'events' && name === 'type'
            ? ({ target: { value } }) =>
                setItem(prevState => ({ ...prevState, [name]: value }))
            : undefined
        }
        options={
          Array.isArray(options)
            ? options
            : options
            ? dbOptions[options]
            : undefined
        }
        readOnly={
          itemType === 'events' &&
          item.type === 'Reitschule' &&
          name.startsWith('date')
        }
        {...props}
      />
    </div>
  )

  const handleDelete = () => {
    if (window.confirm(`${settings.subject.singular} wirklich löschen?`))
      api[itemType].remove(id).then(data => {
        addNotification({
          payload: settings.getLabel(data) + ' wurde erfolgreich gelöscht',
          type: 'primary'
        })
        history.goBack()
      })
  }

  const handleSubmit = async e => {
    e.preventDefault()
    try {
      const data = getFormData(e.target)
      const action = isNew
        ? api[itemType].create(data)
        : api[itemType].patch(id, data)
      const res = await action

      addNotification({
        payload: settings.getLabel(res) + ' wurde erfolgreich gespeichert',
        type: 'success'
      })
      history.goBack()
    } catch (error) {
      addNotification(error)
    }
  }

  useEffect(() => {
    if (!isNew) {
      const p = new PCancelable((resolve, reject) => {
        api[itemType].get(id).then(resolve, reject)
      })
      p.then(setItem).catch(addNotification)
      return () => p.cancel()
    }
  }, [addNotification, id, isNew, itemType])

  useEffect(() => {
    if (itemType === 'reservations') {
      const p = new PCancelable((resolve, reject) => {
        api.events
          .find({
            query: { active: true, $limit: 999, $sort: { dateFrom: 1 } }
          })
          .then(resolve, reject)
      })
      p.then(({ data }) =>
        setDbOptions(prevState => ({
          ...prevState,
          events: data.map(({ _id, title, type }) => ({
            label: `${title} (${type})`,
            value: _id
          }))
        }))
      ).catch(addNotification)
      return () => p.cancel()
    }
  }, [addNotification, itemType])

  useEffect(() => {
    if (itemType === 'events' && item.type === 'Reitschule') {
      setItem(prevState => ({
        ...prevState,
        dateFrom: '2000-01-01',
        dateTo: '2099-12-31'
      }))
    }
  }, [itemType, item.type])

  return (
    <div>
      <Helmet>
        <title>
          {window.document.head.dataset.titlePrefix} | {title}
        </title>
      </Helmet>

      <h1 className='text-3xl mb-8'>{title}</h1>

      <form onSubmit={handleSubmit}>
        <div className='sm:flex flex-wrap -mx-3 -mt-1'>
          {fields.map(({ fieldset, legend, ...field }) =>
            Array.isArray(fieldset) ? (
              <fieldset
                className='border shadow rounded mx-3 mb-8 px-6 py-2 sm:w-full'
                key={legend}
              >
                <legend className='px-2 -mx-2 bg-white text-blue-700 uppercase'>
                  {legend}
                </legend>
                <div className='-mx-6 flex-wrap px-3 sm:flex'>
                  {fieldset
                    .filter(({ hideEditor }) => !hideEditor)
                    .map(getField)}
                </div>
              </fieldset>
            ) : (
              getField(field)
            )
          )}
        </div>

        <div
          className='-mx-6 bottom-0 flex justify-between mt-3 px-3 py-4 sticky'
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.85)' }}
        >
          <div className='mx-3'>
            <Button
              color='danger'
              disabled={state.semaphore > 0 || item.type === 'Reitschule'}
              onClick={handleDelete}
              type='button'
            >
              Löschen
            </Button>
          </div>

          <div className='mx-3'>
            <Button onClick={() => history.goBack()} type='button'>
              Zurück
            </Button>{' '}
            <Button
              color='primary'
              disabled={state.semaphore > 0}
              type='submit'
            >
              Speichern
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}

ItemEditor.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
}

export default ItemEditor
