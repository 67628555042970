export default (defaults = {}) => ({
  fields: [
    {
      defaultValue: defaults.title,
      label: 'Titel',
      name: 'title',
      required: true,
      type: 'text'
    },
    {
      defaultValue: defaults.type,
      label: 'Art des Termins',
      name: 'type',
      options: [
        'Tagesferien',
        'Reiterferien',
        { disabled: true, label: 'Reitschule', value: 'Reitschule' }
      ],
      required: true,
      type: 'select'
    },
    {
      defaultValue: defaults.dateFrom,
      label: 'Datum von',
      name: 'dateFrom',
      required: true,
      type: 'date'
    },
    {
      defaultValue: defaults.dateTo,
      label: 'Datum bis',
      name: 'dateTo',
      required: true,
      type: 'date'
    },
    {
      defaultValue: defaults.allocation,
      label: 'Kontingent',
      name: 'allocation',
      required: true,
      type: 'number'
    },
    {
      defaultValue: defaults._numReservations,
      label: 'Reservierungen',
      name: '_numReservations',
      noQuery: true,
      showAsDiv: true,
      type: 'number'
    },
    {
      defaultValue: defaults.price,
      hideTable: true,
      label: 'Preis',
      name: 'price',
      required: true,
      type: 'number'
    },
    {
      defaultChecked: defaults.active,
      label: 'Aktiv',
      name: 'active',
      type: 'checkbox'
    }
  ],
  getLabel: ({ title, type }) => `Termin "${title} (${type})"`,
  subject: {
    plural: 'Termine',
    singular: 'Termin'
  }
})
