import React from 'react'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { view } from 'react-easy-state'
import colors from '../settings/colors'
import globalState from '../state'
import useNotifications from '../hooks/notifications'

const Notifications = () => {
  const { cancelAutoremove, removeNotification } = useNotifications()

  return (
    <TransitionGroup className='fixed top-0 w-full p-2 pointer-events-none'>
      {globalState.notifications.map(({ id, payload, type }) => (
        <CSSTransition classNames='transition' key={id} timeout={250}>
          <div
            className={`w-full sm:w-1/2 lg:w-1/3 border ${colors[type].fg} ${colors[type].bg} ${colors[type].border} ${colors[type].focus} ${colors[type].hover}
          rounded shadow-xl mb-2 ml-auto px-4 py-2 pointer-events-auto`}
            key={id}
            onTouchStart={() => cancelAutoremove(id)}
            onMouseOver={() => cancelAutoremove(id)}
          >
            <button
              className='block ml-auto hover:text-gray-300'
              onClick={() => removeNotification(id)}
            >
              <span aria-label='acknowledge' role='img'>
                ❌
              </span>
            </button>
            <p>{payload}</p>
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  )
}

export default view(Notifications)
