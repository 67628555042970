import PropTypes from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Button from '../components/Button'

const NotFound = ({ location }) => (
  <div className='flex h-screen justify-center p-4'>
    <Helmet>
      <title>
        {window.document.head.dataset.titlePrefix} | Nicht gefunden (404)
      </title>
    </Helmet>

    <div className='bg-white rounded p-8 self-center text-center shadow-xl'>
      <h1 className='text-6xl'>
        <span role='img' aria-label='confused'>
          😧
        </span>
      </h1>
      <p>
        Unter der Adresse{' '}
        <em style={{ wordBreak: 'break-all' }}>"{location.pathname}"</em> konnte
        kein Inhalt gefunden werden.
      </p>

      <p className='mt-6'>
        <Button component={Link} to='/'>
          Startseite
        </Button>
      </p>
    </div>
  </div>
)

NotFound.propTypes = {
  location: PropTypes.object.isRequired
}

export default NotFound
