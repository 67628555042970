import PCancelable from 'cancelable-promise'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import api from '../api/services'
import Button from '../components/Button'
import Field from '../components/Field'
import Pell from '../components/Pell'
import useNotifications from '../hooks/notifications'
import getReservationSettings from '../settings/reservations'
import getRidingSchoolSettings from '../settings/ridingschool'
import getTemplateSettings from '../settings/templates'
import state from '../state'
import { getFormData } from '../utils/data'

const Settings = () => {
  const statusOpts = getReservationSettings().fields.find(
    f => f.name === 'status'
  )

  statusOpts.options = [
    ...statusOpts.options,
    ...getRidingSchoolSettings().fields.find(f => f.name === 'status').options
  ]

  const { getLabel } = getTemplateSettings()

  const { addNotification } = useNotifications()

  const [templateBody, setTemplateBody] = useState('')
  const [template, setTemplate] = useState({})
  const [type, setType] = useState(statusOpts.options[1])

  const handleSubmit = e => {
    e.preventDefault()

    const data = {
      ...getFormData(e.target),
      body: templateBody
    }

    api.templates.patch(template._id, data).then(
      ({ status }) =>
        addNotification({
          payload: getLabel(status) + ' wurde erfolgreich gespeichert',
          type: 'success'
        }),
      addNotification
    )
  }

  useEffect(() => {
    state.semaphore++

    const p = new PCancelable((resolve, reject) => {
      api.templates
        .find({ query: { status: type, $limit: 1 } })
        .then(resolve, reject)
    })

    p.then(([template]) => {
      setTemplate(template)
      state.semaphore--
    }).catch(addNotification)

    return () => p.cancel()
  }, [addNotification, type])

  return (
    <div>
      <Helmet>
        <title>
          {window.document.head.dataset.titlePrefix} | Einstellungen
        </title>
      </Helmet>

      <h1 className='text-3xl mb-6'>Einstellungen</h1>

      <form onSubmit={handleSubmit}>
        <fieldset className='border shadow rounded mb-8 px-6 py-2 sm:w-full'>
          <legend className='px-2 -mx-2 bg-white text-blue-700 uppercase'>
            E-Mail-Bestätigungen
          </legend>

          <div className='flex flex-wrap justify-end -m-2'>
            <div className='w-full md:w-1/4 p-2'>
              <Field
                {...statusOpts}
                onChange={({ target: { value } }) => setType(value)}
                required={undefined}
                value={type}
              />
            </div>

            <div className='w-full md:w-3/4 p-2'>
              <Field
                defaultValue={template.subject}
                label='Betreff'
                name='subject'
                type='text'
              />
            </div>

            <div className='w-full md:w-1/4 p-2'>
              <Field
                defaultChecked={template.active}
                label='Aktiv'
                name='active'
                type='checkbox'
              />
            </div>

            <div className='w-full md:w-3/4 p-2'>
              <label className='block text-gray-700 text-sm font-bold'>
                Text
              </label>
              <Pell
                initialContent={template.body}
                handleChange={setTemplateBody}
              />
            </div>

            <div className='p-2'>
              <Button color='primary' type='submit'>
                Speichern
              </Button>
            </div>
          </div>
        </fieldset>
      </form>
    </div>
  )
}

export default Settings
