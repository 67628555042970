import React from 'react'
import { view } from 'react-easy-state'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Admin, { routes } from './containers/Admin'
import Book from './containers/Book'
import ErrorBoundary from './containers/ErrorBoundary'
import Footer from './components/Footer'
import Login from './containers/Login'
import NotFound from './containers/NotFound'
import RidingSchool from './containers/RidingSchool'
import Start from './containers/Start'
import Notifications from './components/Notifications'
import ProtectedRoute from './components/ProtectedRoute'
import ScrollToTop from './components/ScrollToTop'
import state from './state'

const App = () => (
  <ErrorBoundary>
    <div
      className='text-gray-800'
      id='app'
      style={{
        filter: state.semaphore > 0 ? 'grayscale(1) brightness(0.9)' : 'none',
        pointerEvents: state.semaphore > 0 ? 'none' : 'auto',
        transition: 'filter 0.2s ease-in 0.5s'
      }}
    >
      <Router>
        <ScrollToTop>
          <Switch>
            {routes.map(({ path }) => (
              <ProtectedRoute component={Admin} exact key={path} path={path} />
            ))}
            <Route component={Book} exact path='/book/:id' />
            <Route component={Login} exact path='/login' />
            <Route component={RidingSchool} exact path='/reitschule' />
            <Route component={Start} exact path='/' />
            <Route component={NotFound} />
          </Switch>
        </ScrollToTop>
        <Footer />
      </Router>
    </div>
    <Notifications />
  </ErrorBoundary>
)

export default view(App)
