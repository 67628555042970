import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import auth from './api/authentication'

const init = async () => {
  try {
    await auth.authenticate()
  } catch (error) {
    console.info(error.message)
  } finally {
    ReactDOM.render(<App />, document.getElementById('root'))
  }
}

console.log(
  '%c✌️\n%cApp by {M/%cP%c}\n%chttps://mundpropaganda.net',
  'font-size:32px',
  'font-size:16px',
  'color:#ff4136;font-size:16px',
  'font-size:16px',
  'font-size:11px'
)

init()
