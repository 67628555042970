import PropTypes from 'prop-types'
import React from 'react'
import colors from '../settings/colors'
import { hashString } from '../utils/data'

const Input = ({
  adminOnly,
  hideTable,
  isInvalid,
  neutral,
  options,
  showAsDiv,
  ...props
}) =>
  props.type === 'radio' ? (
    <div className='font-normal py-2'>
      {options.map(option => (
        <label className='mr-6' key={option}>
          <input
            className='mr-1'
            {...props}
            defaultChecked={option === props.defaultValue}
            defaultValue={undefined}
            key={`${props.name}-${props.defaultValue}-${option}`}
            value={option}
          />
          {option}
        </label>
      ))}
    </div>
  ) : props.type === 'select' ? (
    <select
      className={`${
        neutral
          ? 'font-semibold -ml-2'
          : 'shadow border rounded w-full h-10 py-2 px-3 bg-white text-gray-700 hover:shadow-none focus:shadow-none focus:outline-none'
      } ${
        isInvalid
          ? `${colors.danger.border} ${colors.danger.focus}`
          : colors.default.focus
      }`}
      {...props}
      key={`${props.name}-${props.defaultValue ||
        props.defaultChecked}-${options
        .map(option => option.value || option)
        .join('')}`}
    >
      {options.map(option => (
        <option
          disabled={option.disabled}
          key={`${props.name}-${(typeof option === 'string'
            ? option
            : option.value
          ).toString()}`}
          value={option.value !== undefined ? option.value : option}
        >
          {option.label || option}
        </option>
      ))}
    </select>
  ) : props.type === 'textarea' ? (
    <textarea
      className={`shadow border rounded w-full h-10 py-2 px-3 h-20 hover:shadow-none focus:shadow-none focus:outline-none ${
        isInvalid
          ? `${colors.danger.border} ${colors.danger.focus}`
          : colors.default.focus
      }`}
      {...props}
      key={`${props.name}-${hashString(props.defaultValue)}}`}
    />
  ) : showAsDiv ? (
    <div className='font-normal py-2'>
      {(props.type === 'datetime-local' || props.type === 'date') &&
      props.defaultValue
        ? new Date(props.defaultValue).toLocaleString()
        : props.defaultValue || '–'}
    </div>
  ) : (
    <input
      className={`${
        props.type !== 'checkbox' ? 'shadow border w-full' : ''
      } rounded py-2 px-3 hover:shadow-none focus:shadow-none focus:outline-none ${
        isInvalid
          ? `${colors.danger.border} ${colors.danger.focus}`
          : colors.default.focus
      }`}
      {...props}
      key={`${props.name}-${props.defaultValue || props.defaultChecked}`}
    />
  )

Input.propTypes = {
  adminOnly: PropTypes.bool,
  hideTable: PropTypes.bool,
  isInvalid: PropTypes.bool,
  options: PropTypes.array,
  neutral: PropTypes.bool,
  type: PropTypes.string.isRequired
}

export default Input
