import PropTypes from 'prop-types'
import React from 'react'
import Input from './Input'

const Field = ({ label, ...props }) => {
  const El = props.type === 'radio' ? 'span' : 'label'
  return (
    <>
      <El
        className='block text-gray-700 text-sm font-bold'
        htmlFor={El === 'label' ? props.name : undefined}
      >
        <span dangerouslySetInnerHTML={{ __html: label }} />
        {props.required && <sup>*</sup>}
      </El>
      <Input id={props.name} {...props} />
    </>
  )
}

Field.propTypes = {
  label: PropTypes.string
}

export default Field
