import React from 'react'
import { view } from 'react-easy-state'
import { Link } from 'react-router-dom'
import state from '../state'

const Footer = () => (
  <footer className='p-5 bg-white shadow-inner text-sm flex flex-wrap justify-between'>
    <div>&copy; {new Date().getFullYear()} Ponyhof Maikendiek</div>
    <ul className='flex flex-wrap -mx-2'>
      <li className='mx-2'>
        <Link to='/admin'>{state.session ? 'Admin' : 'Login'}</Link>
      </li>
      <li className='mx-2'>
        <a href='https://ponyhof-maikendiek.de/impressum/'>Impressum</a>
      </li>
      <li className='mx-2'>
        <a href='https://ponyhof-maikendiek.de/allgemeine-geschaeftsbedingungen/'>
          Allgemeine Geschäftsbedingungen
        </a>
      </li>
      <li className='mx-2'>
        <a href='https://ponyhof-maikendiek.de/datenschutzerkla%cc%88rung/'>
          Datenschutzerklärung
        </a>
      </li>
    </ul>
  </footer>
)

export default view(Footer)
