import PCancelable from 'cancelable-promise'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { view } from 'react-easy-state'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import services from '../api/services'
import Header from '../components/Header'
import useNotifications from '../hooks/notifications'
import colors from '../settings/colors'
import getEventSettings from '../settings/events'

const eventTypes = getEventSettings()
  .fields.find(({ label }) => label === 'Art des Termins')
  .options.map(option => option.label || option)

const Td = ({ children, ...props }) => (
  <td className='p-2 truncate' {...props}>
    {children}
  </td>
)

const Start = ({ history, location }) => {
  const params = new URLSearchParams(location.search)
  const savedData = JSON.parse(localStorage.getItem('event')) || {}
  const { addNotification } = useNotifications()
  const [eventType, setEventType] = useState(
    eventTypes.includes(params.get('type'))
      ? params.get('type')
      : savedData.type || 'Tagesferien'
  )
  const [events, setEvents] = useState([])

  useEffect(() => {
    const p = new PCancelable((resolve, reject) => {
      services.events
        .find({ query: { $limit: 999, $sort: { dateFrom: 1 } } })
        .then(resolve, reject)
    })
    p.then(({ data }) => setEvents(data), addNotification)
    return () => p.cancel()
  }, [addNotification])

  const eventsOfType = events.filter(({ type }) => type === eventType)

  return (
    <div style={{ minHeight: '100vh' }}>
      <div className='flex justify-center lg:p-12 p-4'>
        <Helmet>
          <title>{window.document.head.dataset.titlePrefix} | Start</title>
        </Helmet>

        <div className='bg-white lg:w-8/12 md:w-9/12 p-8 rounded shadow-xl w-full xl:w-7/12'>
          <Header noborder />

          <h2 className='text-lg text-gray-700 mb-3'>Bitte auswählen:</h2>

          <ul className='flex flex-wrap'>
            {eventTypes.map(opt => {
              const color = opt === eventType ? colors.primary : colors.default
              return (
                <li key={opt}>
                  <button
                    className={`px-2 py-1 ml-1 mt-1 border border-b-0 rounded-tl rounded-tr text-sm font-bold shadow hover:shadow-none focus:outline-none ${color.bg} ${color.fg} ${color.border} ${color.hover} `}
                    onClick={() => setEventType(opt)}
                    type='button'
                  >
                    {opt}
                  </button>
                </li>
              )
            })}
          </ul>

          <div
            className={`w-full border rounded overflow-auto text-sm shadow hover:shadow-none ${colors.default.border}`}
          >
            <table className='w-full'>
              <thead className='font-semibold'>
                <tr>
                  <Td>Veranstaltung</Td>
                  {eventsOfType.every(({ type }) => type !== 'Reitschule') && (
                    <>
                      <Td>Von</Td>
                      <Td>Bis</Td>
                    </>
                  )}
                  <td />
                </tr>
              </thead>

              <tbody>
                {eventsOfType.length > 0 ? (
                  eventsOfType.map(evt => {
                    const bookedOut = evt._numReservations >= evt.allocation
                    return (
                      <tr
                        className={`hover:bg-gray-200${
                          evt._id === savedData._id ? ' bg-yellow-100' : ''
                        } ${bookedOut ? ' opacity-50' : ''}`}
                        key={evt._id}
                      >
                        <Td>{evt.title}</Td>
                        {evt.type !== 'Reitschule' && (
                          <>
                            <Td>
                              {new Date(evt.dateFrom).toLocaleDateString()}
                            </Td>
                            <Td>{new Date(evt.dateTo).toLocaleDateString()}</Td>
                          </>
                        )}
                        <Td className='p-2 text-right truncate'>
                          {bookedOut ? (
                            '(Ausgebucht)'
                          ) : eventType === 'Reitschule' ? (
                            <Link
                              className={`font-semibold ${colors.default.fg}`}
                              onClick={() =>
                                localStorage.setItem(
                                  'event',
                                  JSON.stringify(evt)
                                )
                              }
                              to='/reitschule'
                            >
                              Jetzt anmelden!
                            </Link>
                          ) : (
                            <Link
                              className={`font-semibold ${colors.default.fg}`}
                              onClick={() =>
                                localStorage.setItem(
                                  'event',
                                  JSON.stringify(evt)
                                )
                              }
                              to={`/book/${evt._id}`}
                            >
                              {bookedOut ? 'Ausgebucht' : 'Jetzt buchen!'}
                            </Link>
                          )}
                        </Td>
                      </tr>
                    )
                  })
                ) : (
                  <tr>
                    <Td colSpan='4'>
                      Zur Zeit sind keine {eventType}-Termine buchbar.
                    </Td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

Start.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired,
  location: PropTypes.object.isRequired
}

export default view(Start)
