import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import client from '../api/client'
import Header from '../components/Header'
import useNotifications from '../hooks/notifications'
import ItemEditor from './ItemEditor'
import ItemTable from './ItemTable'
import Menu from '../components/Menu'
import Settings from './Settings'
import Stub from './Stub'

export const routes = [
  {
    component: Stub,
    path: '/admin'
  },
  {
    component: ItemTable,
    path: '/admin/reservations'
  },
  {
    component: ItemEditor,
    path: '/admin/reservations/edit/:id'
  },
  {
    component: ItemTable,
    path: '/admin/ridingschool'
  },
  {
    component: ItemEditor,
    path: '/admin/ridingschool/edit/:id'
  },
  {
    component: ItemTable,
    path: '/admin/events'
  },
  {
    component: ItemEditor,
    path: '/admin/events/edit/:id'
  },
  {
    component: Settings,
    path: '/admin/einstellungen'
  }
]

const Admin = ({ path }) => {
  const { addNotification } = useNotifications()

  const notify = useCallback(
    reservation => {
      addNotification({
        payload: `Neue Anfrage für "${reservation.name}" eingetroffen!${
          reservation.eventType ? ` (${reservation.eventType})` : ''
        }`,
        type: 'success'
      })
    },
    [addNotification]
  )

  useEffect(() => {
    client.io.on('api/reservations created', notify)
    client.io.on('api/ridingschool created', notify)

    return () => {
      client.io.off('api/reservations created', notify)
      client.io.off('api/ridingschool created', notify)
    }
  }, [notify])

  return (
    <div id='app-shell'>
      <div className='container mx-auto bg-white rounded sm:my-6 px-6 py-4 shadow-xl'>
        <Header />
        <Menu path={path} />

        <Switch>
          <Redirect exact from='/admin' to='/admin/reservations' />
          {routes.map(({ component, path }) => (
            <Route component={component} key={path} exact path={path} />
          ))}
        </Switch>
      </div>
    </div>
  )
}

Admin.propTypes = {
  path: PropTypes.string.isRequired
}

export default Admin
