import io from 'socket.io-client'
import feathers from '@feathersjs/client'

const url =
  process.env.NODE_ENV === 'production'
    ? 'https://buchen.ponyhof-maikendiek.de'
    : 'http://localhost:3034'

const socket = io(url)
const client = feathers()

client.configure(
  feathers.socketio(socket, {
    timeout: 15000
  })
)

client.configure(
  feathers.authentication({
    path: 'api/authentication',
    storage: window.localStorage
  })
)

export default client
