import PCancelable from 'cancelable-promise'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import Tooltip from 'react-tooltip-lite'
import Button from '../components/Button'
import Input from '../components/Input'
import Pagination from '../components/Pagination'
import useNotifications from '../hooks/notifications'
import colors from '../settings/colors'
import getSettings from '../settings/items'
import state from '../state'
import client from '../api/client'
import api from '../api/services'

const defaultQueries = {
  events: {
    active: true,
    $sort: { dateFrom: 1 }
  },
  reservations: {
    $sort: { createdAt: -1 }
  },
  ridingschool: {
    $sort: { createdAt: -1 }
  }
}

const loadQuery = itemType => {
  const data = window.localStorage.getItem(`${itemType}.query`)
  return data ? JSON.parse(data) : defaultQueries[itemType]
}

const removeQuery = itemType => {
  window.localStorage.removeItem(`${itemType}.query`)
  window.location.reload()
}

const saveQuery = (itemType, query) => {
  const data = JSON.stringify(query)
  window.localStorage.setItem(`${itemType}.query`, data)
  return query
}

const ItemTable = ({ location: { pathname } }) => {
  const { format } = new Intl.DateTimeFormat('de', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  })
  const itemType = pathname.split('/')[2]
  const settings = getSettings(itemType, {})
  const fields = settings.fields
    .reduce(
      (acc, field) =>
        field.legend ? [...acc, ...field.fieldset] : [...acc, field],
      []
    )
    .filter(({ hideTable }) => !hideTable)
    .reverse()
    .sort((a, b) => (a.type === 'select' ? 0 : -1))

  const title = settings.subject.plural

  const { addNotification } = useNotifications()
  const printItems = useRef(false)
  const [dbOptions, setDbOptions] = useState({
    events: [{ label: 'Lade…', value: 'loading' }]
  })
  const [items, setItems] = useState({ data: [] })
  const [query, setQuery] = useState(loadQuery(itemType))

  const fetchItems = useCallback(() => {
    const p = new PCancelable((resolve, reject) => {
      api[itemType].find({ query }).then(resolve, reject)
    })
    p.then(setItems).catch(addNotification)
    return () => p.cancel()
  }, [addNotification, itemType, query])

  const setFilter = ({ target: { name, value } }) => {
    setQuery(prevState => {
      const newState = {
        ...prevState,
        [name]: value === '' ? undefined : value,
        $skip: undefined
      }
      return saveQuery(itemType, newState)
    })
  }

  const printAll = () => {
    printItems.current = true
    setQuery(prevState => ({
      ...prevState,
      $skip: 0,
      $limit: 999
    }))
  }

  const setSearch = event => {
    event.preventDefault()
    const $search = event.target.search.value || undefined
    setQuery(prevState => {
      const newState = {
        ...prevState,
        $search
      }
      return saveQuery(itemType, newState)
    })
  }

  const setSkip = $skip => {
    setQuery(prevState => {
      const newState = {
        ...prevState,
        $skip
      }
      return saveQuery(itemType, newState)
    })
  }

  const setSort = (type, field) => {
    setQuery(prevState => {
      const newState = { ...prevState }
      if (type === 'checkbox') {
        newState[field] = prevState[field] ? undefined : true
      } else {
        newState.$sort = {
          [field]: prevState.$sort[field] === 1 ? -1 : 1
        }
      }
      return saveQuery(itemType, newState)
    })
  }

  const showEmails = () => {
    api[itemType]
      .find({ query: { ...query, $limit: 9999 } })
      .then(
        ({ data }) =>
          window.prompt(
            'E-Mail-Adresse der aktuellen Selektion:',
            [...new Set(data.map(d => d.email))].join(', ')
          ),
        addNotification
      )
  }

  useEffect(fetchItems, [itemType, query])

  useEffect(() => {
    if (itemType === 'reservations') {
      const p = new PCancelable((resolve, reject) => {
        api.events
          .find({
            query: { active: true, $limit: 999, $sort: { dateFrom: 1 } }
          })
          .then(resolve, reject)
      })
      p.then(({ data }) =>
        setDbOptions(prevState => ({
          ...prevState,
          events: data.map(({ _id, title, type }) => ({
            label: `${title} (${type})`,
            value: _id
          }))
        }))
      ).catch(addNotification)
      return () => p.cancel()
    }
  }, [addNotification, itemType])

  useEffect(() => {
    client.io.on('api/reservations created', fetchItems)
    return () => client.io.off('api/reservations created', fetchItems)
  }, [fetchItems])

  useEffect(() => {
    if (printItems.current === true) {
      window.print()
      printItems.current = false
    }
  }, [items.data])

  return (
    <div>
      <Helmet>
        <title>
          {window.document.head.dataset.titlePrefix} | {title}
        </title>
      </Helmet>

      <div className='flex flex-wrap justify-between items-center -m-2 mb-4'>
        <div className='flex'>
          <h1 className='text-3xl m-2'>{title}</h1>
          <div className='self-center'>
            <Button component={Link} to={`/admin/${itemType}/edit/new`}>
              Neu
            </Button>
          </div>
        </div>
        <div className='m-2'>
          <form className='text-sm' onSubmit={setSearch}>
            <Input
              autoFocus={!window.navigator.maxTouchPoints}
              defaultValue={query.$search}
              name='search'
              placeholder='Suchen…'
              type='text'
            />
          </form>
        </div>
      </div>

      <div className='overflow-x-auto'>
        <table className='text-left table-collapse'>
          <thead>
            <tr>
              {fields.map(
                ({
                  hideTable,
                  label,
                  name,
                  noQuery,
                  options,
                  tableHeader,
                  type
                }) => (
                  <th
                    className='text-sm p-2 bg-gray-100'
                    key={name}
                    style={{
                      minWidth: type === 'textarea' ? '150px' : undefined
                    }}
                  >
                    {options ? (
                      <span className={colors.neutral.fg}>
                        <Input
                          defaultValue={query[name]}
                          name={name}
                          neutral
                          options={
                            Array.isArray(options)
                              ? [{ label, value: '' }, ...options]
                              : [
                                  { label, value: '' },
                                  ...dbOptions[name.replace(/Id$/, 's')]
                                ]
                          }
                          onChange={setFilter}
                          type='select'
                        />
                      </span>
                    ) : noQuery ? (
                      <span className={colors.neutral.fg}>
                        {tableHeader || label}
                      </span>
                    ) : (
                      <Button
                        color='neutral'
                        onClick={() => setSort(type, name)}
                      >
                        {tableHeader || label}{' '}
                        {type === 'checkbox' && query[name] ? '✔︎' : ''}
                        {!query.$sort[name]
                          ? ''
                          : query.$sort[name] === 1
                          ? '↓'
                          : '↑'}
                      </Button>
                    )}
                  </th>
                )
              )}
            </tr>
          </thead>
          <tbody className='align-baseline'>
            {items.data.map(item => (
              <tr key={item._id}>
                {fields.map(({ name, parse, type }) => {
                  const content = parse
                    ? parse(item)
                    : type.match(/^date/)
                    ? format(new Date(item[name]))
                    : item[name] === true
                    ? '✔︎'
                    : item[name.replace(/^(.*)Id$/, '_$1Label')]

                  return (
                    <td
                      className='p-2 border-t overflow-hidden text-sm whitespace-no-wrap'
                      key={`${item._id}.${name}`}
                      style={{ maxWidth: 250, textOverflow: 'ellipsis' }}
                    >
                      {name === 'notes' ? (
                        <Tooltip
                          arrow={false}
                          content={content}
                          tagName='span'
                          useDefaultStyles
                        >
                          <Link
                            className='no-underline'
                            to={`/admin/${itemType}/edit/${item._id}`}
                          >
                            {content}
                          </Link>
                        </Tooltip>
                      ) : (
                        <Link
                          className='no-underline'
                          to={`/admin/${itemType}/edit/${item._id}`}
                        >
                          {content}
                        </Link>
                      )}
                    </td>
                  )
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {items.data.length > 0 && (
        <div className='my-6'>
          <Pagination
            loading={state.semaphore > 0}
            limit={items.limit}
            setSkip={setSkip}
            skip={items.skip}
            total={items.total}
          />
        </div>
      )}

      <div className='-m-2 flex justify-center print-button text-center w-full'>
        <div className='m-2'>
          <Button color='neutral' onClick={printAll}>
            <span className='text-xl' role='img' aria-label='confused'>
              🖨
            </span>
          </Button>
        </div>

        <div className='m-2'>
          <Button color='neutral' onClick={showEmails}>
            <span className='text-xl' role='img' aria-label='confused'>
              📨
            </span>
          </Button>
        </div>

        <div className='m-2'>
          <Button color='neutral' onClick={() => removeQuery(itemType)}>
            <span className='text-xl' role='img' aria-label='confused'>
              🆑
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}

ItemTable.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
}

export default ItemTable
