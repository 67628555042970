import PropTypes from 'prop-types'
import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import services from '../api/services'
import Button from '../components/Button'
import Field from '../components/Field'
import Header from '../components/Header'
import useNotifications from '../hooks/notifications'
import getRidingSchoolSettings from '../settings/ridingschool'
import { getFormData } from '../utils/data'
import 'date-input-polyfill-react'

const RidingSchool = ({ history: { push } }) => {
  const { addNotification } = useNotifications()

  const [booking, setBooking] = useState(
    JSON.parse(localStorage.getItem('ridingschool')) || []
  )
  const [sent, setSent] = useState(false)
  const [step, setStep] = useState(0)

  const { format } = new Intl.DateTimeFormat('de-DE', {
    month: 'long',
    year: 'numeric'
  })
  const steps = getRidingSchoolSettings().fields.filter(
    ({ adminOnly }) => !adminOnly
  )

  const getData = (data, type) => {
    switch (type) {
      case 'checkbox':
        return '✅'
      case 'date':
        return data ? new Date(data).toLocaleDateString() : ''
      case 'month':
        return format(new Date(data))
      default:
        return data
    }
  }

  const handleSubmit = e => {
    e.preventDefault()
    if (step < steps.length) {
      const data = getFormData(e.target)
      setBooking(prevState => {
        prevState[step] = data
        localStorage.setItem('ridingschool', JSON.stringify(prevState))
        return prevState
      })
      setStep(prevState => prevState + 1)
    } else {
      const bookingRequest = booking.reduce((acc, step) => ({
        ...acc,
        ...step,
        status: 'Eingang bestätigt (Reitschule)'
      }))
      services.ridingschool.create(bookingRequest).then(({ _id }) => {
        setSent(true)
        addNotification({
          payload: `Anfrage erfolgreich versendet! (Nummer ${_id})`,
          type: 'success'
        })
      }, addNotification)
    }
  }

  return (
    <div id='app-shell'>
      <div className='container mx-auto bg-white rounded sm:my-6 px-6 py-4 shadow-xl'>
        <Header />

        <Helmet>
          <title>{window.document.head.dataset.titlePrefix} | Start</title>
        </Helmet>

        <h1 className='text-2xl mb-4'>Veranstaltung buchen</h1>

        <h2 className='text-3xl leading-tight mb-6'>
          Anmeldung zur Reitschule
        </h2>

        <form onSubmit={handleSubmit}>
          <div className='sm:flex flex-wrap -mx-3 -mt-1'>
            <fieldset
              className='border shadow rounded mx-3 mb-8 px-6 py-2 w-full'
              style={{ minWidth: 0 }}
            >
              <legend className='px-2 -mx-2 bg-white text-blue-700 uppercase'>
                {(steps[step] && steps[step].legend) || 'Anfrage absenden'} (
                {step + 1} / {steps.length + 1})
              </legend>
              <div className='-mx-6 flex-wrap px-3 sm:flex'>
                {steps[step] ? (
                  steps[step].fieldset
                    .filter(({ hideEditor }) => !hideEditor)
                    .map(
                      ({
                        name,
                        options,
                        parse,
                        tableHeader,
                        wrap,
                        ...props
                      }) => (
                        <div className='w-full md:w-1/2 px-3 my-3' key={name}>
                          <Field
                            name={name}
                            options={
                              Array.isArray(options) ? options : undefined
                            }
                            {...props}
                            defaultChecked={
                              booking[step] &&
                              (props.type === 'checkbox'
                                ? booking[step][name]
                                : undefined)
                            }
                            defaultValue={
                              booking[step] &&
                              (props.type === 'checkbox'
                                ? undefined
                                : booking[step][name])
                            }
                          />
                        </div>
                      )
                    )
                ) : (
                  <div className='px-3 text-sm w-full'>
                    <p className='italic'>Bitte prüfen Sie Ihre Daten:</p>
                    <div className='overflow-x-auto' style={{ padding: 1 }}>
                      <table className='table-auto mb-3 w-full'>
                        {booking.map((data, step) => (
                          <Fragment key={steps[step].legend}>
                            <thead>
                              <tr>
                                <th
                                  className='text-left pt-4 px-4 pb-2'
                                  colSpan='2'
                                >
                                  {steps[step].legend}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {Object.keys(data).map((key, i) => (
                                <tr key={key}>
                                  <td
                                    className='md:w-1/3 border px-4 py-2'
                                    dangerouslySetInnerHTML={{
                                      __html: `${steps[step].fieldset[i].label}:`
                                    }}
                                  />
                                  <td className='border px-4 py-2'>
                                    {getData(
                                      data[key],
                                      steps[step].fieldset[i].type
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Fragment>
                        ))}
                      </table>
                    </div>
                  </div>
                )}
              </div>
            </fieldset>
          </div>

          <div
            className='-mx-6 bottom-0 flex justify-between mt-3 px-3 py-4 sticky'
            style={{ backgroundColor: 'rgba(255, 255, 255, 0.85)' }}
          >
            <div className='mx-3'>
              <Button
                onClick={() =>
                  step > 0
                    ? setStep(prevState => (prevState > 1 ? prevState - 1 : 0))
                    : push('/')
                }
                type='button'
              >
                Zurück
              </Button>{' '}
            </div>
            <div className='mx-3'>
              <Button
                color={sent ? 'success' : 'primary'}
                disabled={sent}
                type='submit'
              >
                {sent
                  ? 'Anfrage versendet'
                  : step === steps.length
                  ? 'Anfrage absenden'
                  : 'Weiter'}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

RidingSchool.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
}

export default RidingSchool
