const getAge = ({ birthday }) => {
  const birthdate = new Date(birthday)
  const now = new Date()
  return Math.floor((now - birthdate) / (1000 * 60 * 60 * 24 * 365.25))
}

export default (defaults = {}) => ({
  fields: [
    {
      adminOnly: true,
      defaultValue: defaults.status,
      label: 'Status',
      name: 'status',
      options: [
        'Neu (Reitschule)',
        'Eingang bestätigt (Reitschule)',
        'Teilnahme bestätigt (Reitschule)',
        'Storniert (Reitschule)'
      ],
      required: true,
      type: 'select'
    },
    {
      adminOnly: true,
      defaultValue: defaults.createdAt,
      label: 'Eingegangen',
      name: 'createdAt',
      showAsDiv: true,
      type: 'datetime-local'
    },
    {
      adminOnly: true,
      defaultValue: defaults.updatedAt,
      hideTable: true,
      label: 'Zuletzt geändert',
      name: 'updatedAt',
      showAsDiv: true,
      type: 'datetime-local'
    },
    {
      legend: 'Kind',
      fieldset: [
        {
          defaultValue: defaults.name,
          label: 'Vorname, Name',
          name: 'name',
          required: true,
          type: 'text'
        },
        {
          defaultValue: defaults.birthday,
          label: 'Geburtsdatum',
          name: 'birthday',
          parse: getAge,
          required: true,
          tableHeader: 'Alter',
          type: 'date'
        },
        {
          defaultValue: defaults.phone,
          label: 'Telefonnummer',
          name: 'phone',
          type: 'tel'
        },
        {
          defaultValue: defaults.street,
          hideTable: true,
          label: 'Straße, Hausnummer',
          name: 'street',
          required: true,
          type: 'text'
        },
        {
          defaultValue: defaults.city,
          label: 'Postleitzahl, Ort',
          name: 'city',
          required: true,
          type: 'text'
        }
      ]
    },
    {
      legend: 'Erziehungsberechtigte(r)',
      fieldset: [
        {
          defaultValue: defaults.name_parent,
          hideTable: true,
          label: 'Vorname, Name',
          name: 'name_parent',
          required: true,
          type: 'text'
        },
        {
          defaultValue: defaults.email,
          hideTable: true,
          label: 'E-Mail-Adresse',
          max: 200,
          name: 'email',
          required: true,
          type: 'email'
        },
        {
          defaultValue: defaults.phone_parent,
          hideTable: true,
          label: 'Telefonnummer',
          name: 'phone_parent',
          required: true,
          type: 'tel'
        },
        {
          defaultValue: defaults.street_parent,
          hideTable: true,
          label: 'Straße, Hausnummer',
          name: 'street_parent',
          type: 'text'
        },
        {
          defaultValue: defaults.city_parent,
          hideTable: true,
          label: 'Postleitzahl, Ort',
          name: 'city_parent',
          type: 'text'
        }
      ]
    },
    {
      legend: 'Weitere Angaben',
      fieldset: [
        {
          defaultValue: defaults.experience,
          hideTable: true,
          label: 'Reitkenntnisse des Kindes',
          name: 'experience',
          options: [
            'keine',
            'wenige',
            'regelmäßiger Unterricht seit mind. 2 Jahren',
            'regelmäßiger Unterricht seit mind. 4 Jahren',
            'regelmäßiger Unterricht seit mind. 6 Jahren'
          ],
          required: true,
          type: 'select'
        },
        {
          defaultValue: defaults.accident_treatment,
          hideTable: true,
          label:
            'Falls erforderlich, darf Ihr Kind mit Kühlpacks, Pflaster und Jodsalbe behandelt werden?',
          name: 'accident_treatment',
          options: ['ja', 'nein'],
          required: true,
          type: 'radio'
        }
      ]
    },
    {
      legend: 'Bestätigungen',
      fieldset: [
        {
          defaultChecked: defaults.correctness,
          hideTable: true,
          label:
            'Ich versichere, dass ich alle Angaben korrekt und nach bestem Wissen und Gewissen gemacht habe',
          name: 'correctness',
          required: true,
          type: 'checkbox'
        },
        {
          defaultChecked: defaults.toc,
          hideTable: true,
          label:
            'Ich habe die <a href="https://ponyhof-maikendiek.de/allgemeine-geschaeftsbedingungen/" target="_blank">Allgemeinen Geschäftsbedingungen</a> gelesen und zur Kenntnis genommen',
          name: 'toc',
          required: true,
          type: 'checkbox'
        },
        {
          defaultChecked: defaults.privacy,
          hideTable: true,
          label:
            'Ich habe die <a href="https://ponyhof-maikendiek.de/datenschutzerkla%CC%88rung/" target="_blank">Datenschutzerklärung</a> gelesen und zur Kenntnis genommen. Im Falle einer Reitschulbuchung außerdem: Ich  habe die <a href="https://ponyhof-maikendiek.de/reiten-lernen-auf-dem-ponyhof-maikendiek/" target="_blank">Hofordnung</a> gelesen und akzeptiere sie',
          name: 'privacy',
          required: true,
          type: 'checkbox'
        },
        {
          defaultValue: defaults.notes,
          label: 'Hinweise, Wünsche und sonstige Informationen',
          name: 'notes',
          tableHeader: 'Notizen',
          type: 'textarea'
        }
      ]
    }
  ],
  getLabel: ({ name }) => `Anfrage von "${name}"`,
  subject: {
    plural: 'Anfragen',
    singular: 'Anfrage'
  }
})
