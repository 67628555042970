import PropTypes from 'prop-types'
import React from 'react'
import colors from '../settings/colors'

const Button = ({
  color = 'default',
  component = 'button',
  label,
  ...props
}) => {
  const Component = component
  return (
    <Component
      {...props}
      className={`whitespace-no-wrap font-semibold no-underline ${
        colors[color].bg
      } ${colors[color].hover} ${colors[color].fg} ${
        color === 'neutral'
          ? ''
          : 'uppercase text-sm border font-bold py-2 px-3 rounded shadow hover:shadow-none focus:outline-none'
      } ${colors[color].focus} ${colors[color].border}`}
    />
  )
}

Button.propTypes = {
  bgcolor: PropTypes.string,
  color: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string
}

export default Button
