import client from '../api/client'
import state from '../state'

const method = async (endpoint, method, args) => {
  try {
    state.semaphore++
    const service = client.service(endpoint)
    return await service[method](...args)
  } catch (error) {
    throw error
  } finally {
    state.semaphore--
  }
}

export default endpoint => ({
  create: (...args) => method(endpoint, 'create', args),
  find: (...args) => method(endpoint, 'find', args),
  get: (...args) => method(endpoint, 'get', args),
  patch: (...args) => method(endpoint, 'patch', args),
  remove: (...args) => method(endpoint, 'remove', args),
  update: (...args) => method(endpoint, 'update', args)
})
